import store from '@/store'
import config from '@/config/app.config.js' // 引入配置文件
import contractAbi from '../abi/MaiExchange.json' // 质押锁仓合约abi
import web3Obj from '../utils/web3'

const contractAddress = config.maiExchangeAddress
const currentAccount = store.state.myAcount // 当前钱包账户
const contractInstance = new web3Obj.web3.eth.Contract(contractAbi, contractAddress)

// 统一错误返回
const failResult = (message) => ({ success: false, message })
// 统一成功返回
const successResult = (result) => ({ success: true, result })

/**
 * 獲取mai地址
 * mai
 * @return address
 */
const mai = async () => {
  try {
    const resp = await contractInstance.methods
      .mai()
      .call({})
    return successResult(resp)
  } catch (error) {
    return failResult(error)
  }
}

/**
 * 獲取池子u
 * mai
 * @return amount
 */
const getPoolBalance = async () => {
  try {
    var balance = await contractInstance.methods
      .totalUAmount()
      .call({})
    balance = web3Obj.fromWei(balance)
    return successResult(balance)
  } catch (error) {
    return failResult(error)
  }
}

/**
 * 获取tokenList
 * @return
 */
const getTokenList = async () => {
  try {
    const resp = await contractInstance.methods
      .tokenList()
      .call({})
    return successResult(resp)
  } catch (error) {
    return failResult(error)
  }
}

/**
 * 获取initPrice
 * @return
 */
const initPrice = async () => {
  try {
    const resp = await contractInstance.methods
      .initPrice()
      .call({})
    return successResult(web3Obj.fromWei(resp))
  } catch (error) {
    return failResult(error)
  }
}

/**
 * 获取lastPrice
 * @return
 */
const lastPrice = async () => {
  try {
    const resp = await contractInstance.methods
      .lastPrice()
      .call({})
    return successResult(web3Obj.fromWei(resp))
  } catch (error) {
    return failResult(error)
  }
}
/**
 * 贖回
 * withdraw
 * @token address
 * @amount uint256
 */
const withdraw = async (tokenAddress, amount) => {
  amount = web3Obj.toWei(amount)
  try {
    const resp = await contractInstance.methods
      .withdraw(tokenAddress, amount)
      .send({ from: currentAccount || store.state.myAcount, ...web3Obj.gasPriceObj })
    return successResult(resp)
  } catch (error) {
    return failResult(error)
  }
}

/**
 * 获取储备金--u
 * @return
 */
const totalUAmount = async () => {
  try {
    const resp = await contractInstance.methods
      .totalUAmount()
      .call({})
    return successResult(web3Obj.fromWei(resp))
  } catch (error) {
    return failResult(error)
  }
}

export default {
  mai,
  getPoolBalance,
  getTokenList,
  initPrice,
  lastPrice,
  withdraw,
  totalUAmount
}
