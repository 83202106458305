<template>
  <div id="echarts"></div>
</template>

<script>
import { queryMaiPriceList } from '../../../services/maiPrice'
// import echarts from 'echarts'
const echarts = require('echarts')
export default {
  name: '',
  data () {
    return {
      charts: '',
      opinions: [],
      opinionData: [],
      temp: []
    }
  },
  methods: {
    async init () {
      const resp = await queryMaiPriceList()
      if (!resp.success) return
      this.temp = (resp.data || []).reverse()
      for (let i = 0; i < this.temp.length; i++) {
        var str = ''
        str += this.temp[i].createTime.split(' ')[0]
        this.opinions.push(str)
        this.opinionData.push(this.temp[i].lastPrice)
      }
      const minPrice = Math.min(...this.opinionData)
      this.charts = echarts.init(document.getElementById('echarts'))
      this.charts.setOption(
        {
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['MAI Price']
          },
          grid: {
            left: '0%',
            right: '3%',
            bottom: '3%',
            containLabel: true
          },
          toolbox: {
            feature: {
              // saveAsImage: {}
            }
          },
          // dataZoom: [{
          //   type: 'slider',
          //   show: false, // flase直接隐藏图形
          //   xAxisIndex: [0],
          //   left: '9%', // 滚动条靠左侧的百分比
          //   bottom: -5,
          //   start: 0, // 滚动条的起始位置
          //   end: 30 // 滚动条的截止位置（按比例分割你的柱状图x轴长度）
          // }],
          xAxis: {
            type: 'category',
            boundaryGap: false,
            axisLabel: {
              // interval: 0,
              rotate: 15
            },
            data: this.opinions
          },
          yAxis: {
            type: 'value',
            name: 'MAI Price',
            smooth: true,
            min: minPrice,
            axisLabel: {
              formatter: '{value} U'
            }
          },

          series: [{
            name: 'MAI Price',
            type: 'line',
            stack: 'price',
            color: '#6667FF',
            data: this.opinionData
          }]
        }
      )
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.init()
    })
  }
}
</script>

<style scoped lang="scss">
#echarts {
  width: calc(100% + 24px);
  margin-left: -24px;
  height: 350px;
}
</style>
