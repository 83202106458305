<template>
  <div class="wrapper">
    <div class="title">{{contribution.contributionToken}}</div>
    <div class="slogan">
      <div class="slogan1">{{contribution.slogan1}}</div>
      <div>{{contribution.slogan2}}</div>
    </div>
    <div class="box-group">
      <div class="box box-one">
        <div class="box-text">
          <div>{{contribution.redemptionPrice}}</div>
          <div class="into-icon" @click="handleToRedeem"><img src="@/assets/contribution/into-icon.png" /></div>
        </div>
        <div class="box-num">{{$gbUtils.formatEightBalance(maiPrice) || 0}} MUSD</div>
      </div>
      <div class="box">
        <div class="box-text">{{contribution.totalIssuance}}</div>
        <div class="box-num">{{$gbUtils.formatTwoBalance(maiTotal) + ' '+ maiSymbol}}</div>
      </div>
      <div class="box">
        <div class="box-text">{{contribution.reserve}}</div>
        <div class="box-num">{{$gbUtils.formatTwoBalance(musdTotal)}} MUSD</div>
      </div>
      <!-- <div class="box">
        <div class="box-text">{{contribution.addresses}}</div>
        <div class="box-num">{{totalAddress || 0}}</div>
      </div> -->
    </div>
    <MaiPriceChart/>
    <!-- <div class="block">
      <div class="title-line">
        <div class="block-title">{{contribution.contributionWithdrawal}}</div>
        <van-button class="btn">{{contribution.yuanqiCrowdfunding}}</van-button>
      </div>
      <div class="inset-block">
        <div class="column-title">
          <div class="column-one">{{contribution.quantity}}</div>
          <div class="column-two">{{contribution.source}}</div>
          <div class="column-three">{{contribution.time}}</div>
        </div>
        <div class="column-content" v-for="item in 4" :key="item">
          <div class="column-one">355.89</div>
          <div class="column-two">{{contribution.metaCrowd}}</div>
          <div class="column-three">2022-10-12 00:43</div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import config from '@/config/app.config'
import contractExchange from '../../contracts/contractExchange'
import contractToken from '../../contracts/contractToken'
import MaiPriceChart from './components/MaiPriceChart.vue'
export default {
  name: 'contribution',
  components: { MaiPriceChart },
  data () {
    return {
      maiAddress: '',
      maiPrice: 0,
      initPrice: 0,
      lastPrice: 0,
      maiSymbol: '-',
      maiTotal: 0,
      reserveU: 0,
      totalAddress: 0,
      musdTotal: 0
    }
  },
  computed: {
    contribution () {
      return this.$t('contribution')
    }
  },
  methods: {
    async init () {
      this.getMaiPrice()
      this.getReserveU()
      this.getMusdTotal()
      await this.getMaiAddress()
      this.getMaiSymbol()
      this.getMaiTotal()
    },
    async getMaiPrice () {
      const resp1 = await contractExchange.initPrice()
      const resp2 = await contractExchange.lastPrice()
      if (resp1.success) {
        this.initPrice = resp1.result || 0
      }
      if (resp2.success) {
        this.lastPrice = resp2.result || 0
      }
      this.maiPrice = this.initPrice > 0 ? this.initPrice : this.lastPrice
    },
    async getReserveU () {
      const resp = await contractExchange.totalUAmount()
      if (!resp.success) return
      this.reserveU = resp.result
    },
    async getMaiAddress () {
      const resp = await contractExchange.mai()
      if (!resp.success) return
      this.maiAddress = resp.result
    },
    async getMaiSymbol () {
      const resp = await contractToken.getSymbol(this.maiAddress)
      if (!resp.success) return
      this.maiSymbol = resp.result
    },
    async getMaiTotal () {
      const resp = await contractToken.totalSuplly(this.maiAddress)
      if (!resp.success) return
      this.maiTotal = resp.result || 0
    },
    async getMusdTotal () {
      const resp = await this.$web3.balanceOf(config.MUSD, config.maiExchangeAddress)
      if (!resp.success) return
      this.musdTotal = resp.result
    },
    async handleToRedeem () {
      window.open('https://matrixdaos.finance/')
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style lang="scss">
.wrapper {
  width: 100%;
  min-height: 100vh;
  background: #18191A;
  box-sizing: border-box;
  padding: 20px 24px;
  color: #FFFFFF;
  .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
  .slogan {
    font-size: 23px;
    font-weight: 500;
    margin: 30px auto 0;
    width: fit-content;
    text-align: center;
    .slogan1 {
      background: linear-gradient(90deg, #FFAD43 22.52%, #FF7C6B 38.33%, #FD5090 53.24%, #662BFC 65.89%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .box-group {
    margin-top: 30px;
    // display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .box {
      flex-shrink: 0;
      width: 100%;
      height: 81px;
      background: #252525;
      border-radius: 25px;
      box-sizing: border-box;
      padding: 15px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &.box-one {
        height: 89px;
      }
      .box-text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
      }
      .box-num {
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
  .block {
    width: 100%;
    background: #252525;
    border-radius: 25px;
    box-sizing: border-box;
    padding: 20px;
    .title-line {
      display: flex;
      justify-content: space-between;
      .block-title {
        font-size: 16px;
        font-weight: 500;
      }
      .btn {
        padding: 0 10px;
        height: 26px;
        background: #6667FF;
        border-radius: 30px;
        font-size: 12px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 26px;
      }
    }
    .inset-block {
      width: 100%;
      margin-top: 20px;
      background: #18191A;
      box-sizing: border-box;
      padding: 20px;
      border-radius: 20px;
      font-size: 12px;
      .column-title {
        color: rgba(255,255,255,0.6);
        text-align: center;
        display: flex;
        justify-content: space-between;
      }
      .column-one {
        width: 71px;
      }
      .column-two {
        width: 60px;
      }
      .column-three {
        width: 96px;
      }
      .column-content {
        display: flex;
        justify-content: space-between;
        text-align: center;
        margin-top: 15px;
      }
    }
  }
}
</style>
