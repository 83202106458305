import axios from 'axios'
import { Toast } from 'vant'
import config from '@/config/app.config'
import store from '@/store'

// import router from '../router'

axios.defaults.timeout = 8000

const request = (
  method,
  url,
  params,
  needToken = true,
  needLoading = true,
  baseUrl = '',
  headerContentType = 'application/json'
) => {
  const headers = {
    'Content-type': headerContentType
  }
  const signData = store.state.signData
  if (needToken) {
    headers.message = signData.message
    headers.signature = signData.signature
    headers.address = signData.address
  }
  if (needLoading) {
    // 显示全局加载loading
    Toast.loading(
      {
        duration: 0
      }
    )
  } else {
  }
  return new Promise((resolve, reject) => {
    axios({
      method,
      headers,
      baseURL: baseUrl || config.flashMallServe,
      url,
      timeout: 15000,
      params: method === 'GET' || method === 'DELETE' ? params : null, // 是即将与请求一起发送的 URL 参数
      data: method === 'POST' || method === 'PUT' ? params : null // 是作为请求主体被发送的数据
    })
      .then(res => {
        Toast.clear()
        if (res.data.code === 1) {
          res.data.success = true
          resolve(res.data)
        } else {
          Toast.fail(res.data.message)
          // reject(error)
          resolve({
            success: false,
            result: '',
            message: res.data.message || 'Network exception'
          })
        }
      }).catch((error) => {
        Toast.clear()
        let messageText = ''
        if (
          error.response &&
        error.response.data &&
        error.response.data.message
        ) {
          messageText = error.response.data.message
        } else {
        // messageText = error.response ? getErrorMessage(error.response.status) : 'Network exception'
          messageText = 'Network exception'
        }
        Toast.fail(messageText)
        // reject(error)
        resolve({
          success: false,
          records: [],
          result: {},
          total: 0,
          message: 'Network exception'
        })
      })
  })
}

export default request
